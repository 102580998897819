<template>
  <div class="blog">
    <div class="blog__container">
      <NewestPosts :posts="newestPosts" />
      <TagPosts :post-previews="previews.first" :tag="getTagByGroup('1')" />
      <NewestPostPreview :post="fourthPreview" />
      <Subscription />
      <TagPosts :post-previews="previews.second" :tag="getTagByGroup('2')" />
      <TagPosts :post-previews="previews.third" :tag="getTagByGroup('3')" />
    </div>
  </div>
</template>

<script setup>
import { useStrapiApi } from "~/uses/useMyFetch";
import Subscription from "~/nuxt_modules/blog/ui/components/Subscription.vue";
import TagPosts from "~/nuxt_modules/blog/ui/pages/Blog/TagPosts.vue";
import { getPostsQuery } from "~/nuxt_modules/blog/utils/query.utils";
import { useBlogStore } from "~/nuxt_modules/blog/store/blogStore";
import NewestPosts from "~/nuxt_modules/blog/ui/pages/Blog/NewestPosts.vue";
import NewestPostPreview from "~/nuxt_modules/blog/ui/pages/Blog/NewestPostPreview.vue";

const getTagByGroup = (group) => {
  return useBlogStore().tags.find((tag) => tag.group === group);
};

const buildRequestUrl = (filters = undefined) => {
  return `/blog/posts?${getPostsQuery({ page: 1, pageSize: 4 }, filters)}`;
};

const { data: previews } = await useAsyncData("fetchBlogPreviews", async () => {
  const [main, first, second, third] = await Promise.all([
    useStrapiApi(buildRequestUrl()),
    useStrapiApi(
      buildRequestUrl({
        tags: {
          slug: getTagByGroup("1")?.slug,
        },
      }),
    ),
    useStrapiApi(
      buildRequestUrl({
        tags: {
          slug: getTagByGroup("2")?.slug,
        },
      }),
    ),
    useStrapiApi(
      buildRequestUrl({
        tags: {
          slug: getTagByGroup("3")?.slug,
        },
      }),
    ),
  ]);

  return {
    main: main.data,
    first: first.data,
    second: second.data,
    third: third.data,
  };
});

const newestPosts = computed(() => {
  return previews.value.main.slice(0, 3);
});

const fourthPreview = computed(() => {
  return previews.value.main[previews.value.main.length - 1];
});
</script>

<style scoped lang="scss">
.blog {
  padding-inline: 16px;

  &__container {
    @extend %width-main;

    @include flex-container(column, flex-start);
    gap: 16px;

    margin-inline: auto;
    padding-bottom: 16px;
  }
}
</style>
